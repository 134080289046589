.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}



.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill: #f8dc52 !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}
.loader_section_::before{
  content: "";
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}

.loading svg{
  z-index: 1501;
  position: relative;

}
.form-control:disabled, .form-control[readonly] {
  background-color: transparent !important;
  cursor: not-allowed;
  opacity: 1;
}

button:disabled, button[disabled] {
  opacity: .3;
}

.st-center{
  z-index: 0 !important;
}
button.react-share__ShareButton {
  margin-left: 6px;
}



button.bidwthbtn {
  color: #000;
  border: none;
  background: #e3a511;
  font-size: 14px;
  padding: 3px 20px;
  margin-left: 6px;
  position: relative;
  top: -2px;
  border-radius: 50px;
  cursor: pointer;
}
.Hightbtn {
  color: #000;
  border: none;
  background: #e3a511;
  font-size: 16px;
  padding: 5px 20px;
  margin-left: 6px;
  margin-right: 6px;
  position: relative;
  top: -2px;
  border-radius: 50px;
  cursor: pointer;
}
.bzRnkJ {
  color: rgb(255 255 255 / 72%) !important;
  background-color: #ffffff0f !important;
}
.kVrXuC {
  color: rgb(255 255 255 / 87%) !important;
  background-color: #000000  !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #000000 !important;
  background-color: #da9409 !important;
}
a#pills-profile-tab, a#pills-home-tab {
  border: 2px solid #f2b72b !important;
  margin-left: 10px !important;
  color: #f4f4f4;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 15px;
}

.react-tel-input .form-control{
  font-size: 16px !important;
  color: #b8b8b8 !important;
  background: transparent !important;
  border: 1px solid transparent !important;
}
.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag:hover, 
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: #b3b1ad00 !important;
    border: 0px solid #cacaca00 !important; 
}
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: #000000 !important;
}

ul.country-list {
  background: #222 !important;
}
.react-tel-input .form-control{
  width: 100% !important;
}
@media screen and (max-width:480px) {
  .mobive {
    width: 50% !important;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: -6px;
}

  section.community_section {
    padding-top: 20px;
  }
  .mobileForAlign{
    margin: -25px !important;
    margin-top: 20px !important;
  }
  button.bidwthbtn{
    padding: 3px !important;
  }
  .copidtxt{
    position: inherit !important;
  }
  h3.main_title_small {
    margin-top: 40px;
}
}
