body {
  margin: 0;
  padding: 0;
  font-family: 'Archivo', sans-serif;
  background-color: #000000;
  color: #d6dadf;
}

a {
  color: #1d9ae3;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #f2b72b;
}

.main_navbar {
  background-color: transparent !important;
  z-index: 10;
  transition: 0.2s all;
}

.main_navbar .primary_btn {
  padding: 12px 30px;
}

.main_navbar.fixed_navbar {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #0d0d0d !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 999;
}

.main_navbar.fixed_navbar {
  top: -40px;
  transform: translateY(40px);
  transition: transform .3s;
}

.main_navbar .navbar-toggler:focus {
  box-shadow: 0 0 0 0.14rem #f2b72b;
}

.main_navbar .navbar-brand:focus-visible {
  outline: 0px;
}

.navbar_right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}

.logout_btn {
  background: #333333;
  border: 2px solid #f2b72b;
}

.logout_btn img {
  margin-right: 10px;
}

.page_header {
  position: relative;
  padding: 100px 0;
  background-color: #000000;
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  margin-top: -90px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.page_header h1 {
  font-size: 48px;
  font-weight: 600;
  max-width: 55%;
  margin: 0 auto;
  background: linear-gradient(171deg, #eea90b, #f8dc54);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #ffea00;
}

.page_header p {
  font-size: 18px;
  font-weight: 400;
  color: #d6dadf;
  max-width: 60%;
  margin: 30px auto 40px;
}

.page_header .coin_img {
  display: none;
}

.primary_btn {
  background: linear-gradient(100deg, #fad126 0%, #d99208 100%);
  border: 0;
  border-radius: 5px;
  padding: 12px 50px;
  font-size: 16px;
  font-weight: 600;
  color: #010107;
  text-decoration: none;
}

.primary_btn:hover {
  background: linear-gradient(291deg, #fad126 0%, #d99208 100%);
  text-decoration: none;
  color: #010107;
}

.primary_btnlogout {
  background-color: transparent;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 12px 50px;
  font-size: 16px;
  font-weight: 600;
}

section {
  padding: 80px 0;
}

.main_title_small {
  font-size: 20px;
  font-weight: 500;
  color: #9d9d9d;
  white-space: nowrap;
}

.main_title {
  font-size: 50px;
  font-weight: 700;
  color: #f8dc52;
  position: absolute;
  white-space: nowrap;
}

.community_section {
  padding: 80px 0 135px;
}

.community_section .whole_box {
  padding: 50px;
  border-radius: 80px;
}

.community_section .whole_box .above_box {
  background-color: #0b0b0b;
  position: relative;
  z-index: -1;
  border-radius: 80px 80px 0 0;
  padding: 50px 30px;
  margin-top: -75px;
}

.community_section .whole_box .blw_box {
  background-color: #0b0b0b;
  border-radius: 0 0 80px 80px;
  padding: 10px;
}

.community_section .whole_box .para {
  max-width: 80%;
  margin-left: auto;
  margin-bottom: 0px;
  font-size: 18px;
}

.community_section .whole_box .box {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
}

.community_section .whole_box .box p {
  display: none;
}

.community_section .whole_box .col-xl-3:nth-child(1) .box p {
  display: block;
}

.community_section .whole_box .col-xl-3:nth-child(1) .box {
  align-items: flex-start;
  background-color: #141414;
  padding: 20px;
  border-radius: 30px;
  position: absolute;
}

.community_section .whole_box .box:hover {
  align-items: flex-start;
  background-color: #141414;
  padding: 15px;
  border-radius: 30px;
  position: absolute;
}

.community_section .whole_box .box:hover p {
  display: block;
}

.community_section .whole_box .box h5 {
  margin-top: 15px;
  color: #f8dc52;
  font-size: 24px;
}

.community_section .whole_box .box img {
  height: 48px;
}



.counter_sectionbg {
  background: #0d0d0d;
  padding: 40px 25px;
  border-radius: 80px;
}

.counter_section .box {
  overflow: hidden;
}

.counter_section h3 {
  color: #f8dc52;
  font-size: 36px;
  margin-bottom: 0;
  margin-top: 15px;
}

.counter_section h6 {
  color: #d1d1d1;
  font-size: 13px;
}

.counter_section .col-md-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #272727;
  border-bottom: 1px solid #000000;
  padding: 15px 0;
  text-align: center;
}

.counter_section .col-md-6:nth-child(even) {
  border-left: 1px solid #000000;
  border-right: 0;
}

.counter_section .col-md-6:nth-child(3) {
  border-top: 1px solid #272727;
  border-bottom: 0;
}

.counter_section .col-md-6:nth-child(4) {
  border-top: 1px solid #272727;
  border-bottom: 0;
}

.counter_section p {
  color: #d1d1d1;
  font-size: 18px;
  margin-bottom: 30px;
}

.plans {
  text-align: center;
}

.plans h2 {
  color: #f8dc52;
  font-size: 50px;
  text-align: center;
  margin-bottom: 20px;
}

.plans .tree .tree_structure {
  display: none;
}

.plans h5 {
  font-size: 20px;
  color: #9d9d9d;
  text-align: center;
  margin: 20px 0;
}

.plans .flex {
  display: flex;
  align-items: center;
}

.dashboard_top_wrap .plans .flex .box {
  opacity: 0.5;
}

.dashboard_top_wrap .plans .flex .box.active {
  opacity: 1;
}

.plans .flex .box {
  background: linear-gradient(347deg, #0c0c0c, #161616);
  margin: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}

.plans .flex .box p {
  color: #d1d1d1;
  font-weight: 600;
}

.plans .flex h4 {
  color: #f8dc52;
  font-size: 38px;
  font-weight: bold;
}

.join_now .plans h5 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
}

.join_now .plans .flex .active,
.join_now .plans .flex .box:hover {
  border: 1px solid #da9409 !important;
}

.bg_img {
  background: transparent url("../src/assets/images/dash_bg.png") no-repeat;
  background-position: top;
  background-size: cover;
}

/* 
.community_section [class*=col-],
.partners_section [class*=col-] {
  margin-bottom: 30px;
}
.counter_section {
  position: relative;
  z-index: 10;
}
.counter_section::before {
  content: "";
  background: url("../src/assets/images/counter_bg.png") no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: 506px;
  position: absolute;
  bottom: -61px;
  z-index: -1;
}
.counter_section p {
  font-size: 20px;
  color: #e8d7f8;
  text-align: center;
  margin-bottom: 80px;
}

.counter_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.counter_panel h3 {
  font-size: 56px;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
  margin: 0;
}
.counter_panel h6 {
  font-size: 18px;
  font-weight: 400;
  color: #d6dadf;
  text-transform: uppercase;
  margin: 0;
}
.counter_panel div {
  position: relative;
  z-index: 1;
}
.counter_panel div::before {
  z-index: -1;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.counter_panel div:nth-child(1)::before {
  content: "";
  background: url("../src/assets/images/count_icon_01.png") no-repeat;
  width: 128px;
  height: 112px;
}
.counter_panel div:nth-child(2)::before {
  content: "";
  background: url("../src/assets/images/count_icon_02.png") no-repeat;
  width: 128px;
  height: 128px;
}
.counter_panel div:nth-child(3)::before {
  content: "";
  background: url("../src/assets/images/count_icon_03.png") no-repeat;
  width: 130px;
  height: 130px;
}
.counter_panel div:nth-child(4)::before {
  content: "";
  background: url("../src/assets/images/count_icon_04.png") no-repeat;
  width: 135px;
  height: 132px;
} */
/* .primary_box.partner_box {
  flex-direction: row;
  padding: 30px 20px;
  align-items: center;
  cursor: default;
}
.partner_box_left {
  width: 40%;  
}
.partner_box_right {
  width: 60%;
  position: relative;
}
.partner_box_right::before {
  content: "";
  background-color: #031542;
  width: 2px;
  height: 190px;
  position: absolute;
  top: 6px;
  left: -25px;
}
.partner_box_left h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 15px;
}
.partner_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.partner_box_right p {
  font-size: 18px;
  color: #e8d7f8;
  margin: 0;
}
.partner_box_right h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
.partner_box_right div {
  margin-bottom: 15px;
}
.commision_table {
  border: 2px solid #0b1c47;
  border-radius: 15px 15px 0 0;
  background: #020f31;
  overflow: hidden;
  margin: 40px 0 0;
}
.commision_table th,
.commision_table td {
  color: #d6dadf;
  font-size: 20px;
  font-weight: 400;
  border: 0 !important;
  vertical-align: middle;
}
.commision_table_div {
  border-radius: 10px;
}
.commision_table th {
  background-color: #198eec;
}
.commision_table tbody {
  border: 3px solid #0b1c47;
}
.commision_table tbody tr:nth-child(even) {
  background-color: #031440;
} */
.faq_section .h2 {
  color: #f8dc52;
  font-size: 50px;
}

.faq_section .para {
  color: #d1d1d1;
  font-size: 18px;
}

#faq_accodian {
  max-width: 60%;
  margin: 0 auto;
  margin-top: 50px;
}

#faq_accodian .card::before {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 85%;
  margin: auto 0;
  top: 0;
  bottom: 0;
  background-color: #0b0b0b;
}

#faq_accodian .card:not(.collapsed)::before {
  background-color: #f8dc52;
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 85%;
  margin: auto 0;
  top: 0;
  bottom: 0;
}

#faq_accodian .card-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 24px;
  font-weight: 500;
  color: #d1d1d1;
  text-decoration: none;
  padding: 0;
  width: 100%;
}

#faq_accodian .card:not(.collapsed) button {
  color: #f8dc52;
}

#faq_accodian .card:not(.collapsed) button::after {
  content: "";
  background-image: url("../src/assets/images/close_icon1.png");
  width: 111px;
  height: 111px;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  transition: 0.2s all;
}

#faq_accodian .card.collapsed button::after {
  content: "";
  background-image: url("../src/assets/images/plus_icon.png");
  width: 111px;
  height: 111px;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  transition: 0.2s all;
}

#faq_accodian .card-header,
#faq_accodian .card-body {
  background-color: transparent;
}

#faq_accodian .card {
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: transparent;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
  transition: 0.2s all;
  margin-bottom: 30px;
  overflow: unset;
}

#faq_accodian .card-body {
  padding-top: 0;
  padding-bottom: 0;
}

#faq_accodian .card-body p {
  color: #d1d1d1;
  font-size: 16px;
  cursor: default;
}

.footer {
  background-color: #000000;
  padding: 30px 0 20px;
  border-top: 2px solid #0b0b0b;
}

.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_panel_left p {
  margin-bottom: 0;
  font-size: 16px;
  color: #b5b5b5;
}

.footer_panel_left p span {
  color: #f8dc52;
}

.footer_panel_left a {
  font-size: 28px;
  color: #1c97e5;
  white-space: normal;
  word-break: break-all;
}

.footer_social_links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer_social_links li {
  margin: 0 5px;
}

.footer_social_links li a {
  width: 44px;
  height: 44px;
  background: #44505c;
  border: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_social_links li a:hover {
  background: #dcc231;
  color: #000;
}

.lan_select button {
  border: 2px solid #0b1c47;
  background: #021039;
  border-radius: 30px;
  color: #feffff;
  font-size: 18px;
  padding: 5px 15px;
}

.lan_select button::after {
  border-top: 5px solid #ffffff;
}

.lan_select button[aria-expanded="true"]::after {
  border-bottom: 5px solid #ffffff;
}

.lan_select ul {
  background: #031440;
  border: 2px solid #0b1c47;
  padding: 0;
}

.lan_select ul li:hover,
.lan_select ul li:focus {
  background-color: #020125;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
}

.inner_wrapperbg {
  background: black;
  padding: 20px;
  border-radius: 25px;
}

.primary_inp {
  border: 2px solid #2a2a2a;
  background: transparent;
}

.title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 13px;
  border-bottom: 1px solid #2a2a2a;
}

.title_box {
  display: flex;
  align-items: center;
  background: #e8a010;
  border-radius: 10px;
}

.title_box img {
  padding: 5px;
}

.title_box .box {
  display: flex;
  align-items: center;
  background: linear-gradient(326deg, #fad126, #d99208);
  width: 100%;
  border-radius: 0 10px 10px 0;
  padding: 10px;
  justify-content: flex-end;
}

.title_box .box p {
  margin-bottom: 0;
  width: 100%;
  padding: 0 10px;
  color: #000000;
}

.title_box .box button {
  margin: 0 8px;
  background: #121212;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  padding: 7px 50px;
}



.inner_title {
  font-size: 36px;
  font-weight: 700;
  color: #f8dc52;
  margin-bottom: 0;
}

.wallet_div {
  display: flex;
  align-items: center;
}

.wallet_div label {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  margin: 0px 15px 0 0;
}

.primary_inp {
  border: 2px solid #2a2a2a;
  background: transparent;
  color: #b8b8b8 !important;
  font-size: 16px;
  border-radius: 10px;
}

select.primary_inp {
  background: transparent url("../src/assets/images/down_arrow.png") no-repeat right 12px center !important;
}

.primary_inp:focus,
.form-control:focus {
  border: 2px solid #2a2a2a;
  background: transparent;
  box-shadow: 0 0 0 0.15rem rgb(42 42 42) !important;
}

.inp_grp {
  position: relative;
}

.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}

.wallet_div .inp_grp,
.dashbox_middle_bottom .inp_grp {
  user-select: none;
}

.wallet_div input.primary_inp {
  width: 300px;
  pointer-events: none;
  cursor: not-allowed;
}

.primary_box.dash_box {
  border-radius: 15px;
  padding: 15px 25px;
  justify-content: start;
  background: linear-gradient(300deg, #0c0c0c, #161616);
  margin: 15px 0;
}

.primary_box.dash_box::before {
  background: rgb(193, 243, 158);
  background: -webkit-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: -o-linear-gradient(300deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
  background: linear-gradient(150deg, rgba(193, 243, 158, 1) 0%, rgba(193, 243, 158, 1) 16%, rgba(28, 154, 229, 1) 32%, rgba(25, 118, 225, 1) 50%, rgba(17, 69, 147, 1) 68%, rgba(10, 75, 195, 1) 84%, rgba(29, 153, 232, 1) 100%);
}

.box_left .primary_inp {
  background: #000000;
}

.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #000000;
}

.dash_box .bor_top {
  border-top: 1px solid #272727;
  padding-top: 10px;
}

.dash_box .bor_top h6 {
  color: #f8dc52;
  font-size: 18px;
  font-weight: 500;
}

.dash_box .bor_top p {
  font-size: 18px;
  font-weight: 500;
}

.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}

.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.box_left h3 {
  font-size: 25px;
  font-weight: 400;
  margin: 0;
  color: #ffffff;
  margin-left: 10px;
}

.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}

.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}

.dash_box_left h4 {
  color: #f8dc52;
  font-size: 28px !important;
  font-weight: 600;
}

.box_left h4 {
  color: #f8dc52;
  font-size: 24px;
}

.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 10px 0;
}

.dashbox_middle_top img {
  width: 37px;
  height: 37px;
}

.dashbox_middle_top h2 {
  font-size: 36px;
  font-weight: 600;
  color: #f8dc52;
  margin: 10px 0 0;
}

.dashbox_middle_top h4 {
  font-size: 14px;
  font-weight: 400;
  color: #d1d1d1;
}


.dashbox_middle_bottom ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
}

.dashbox_middle_bottom ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}

.dashbox_middle_bottom ul.footer_social_links li {
  margin: 0 10px 0px 0;
}

.dashboard_top_wrap>[class*=col-]:last-child {
  margin-bottom: 0;
}

.search_div {
  width: 45%;
  display: flex;
  align-items: center;
  margin: 15px auto;
}

.search_div label {
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
  font-size: 18px;
  color: #ffffff;
}

.search_div button {
  background: #000000;
  border: 2px solid #2a2a2a;
  border-left: 0;
  border-radius: 0 10px 10px 0;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 0px;
  right: 0;
  padding: 6px 10px;
  bottom: 0;
  margin: auto;
}

.plan_pool {
  max-width: 50%;
  margin: 0 auto;
}

.plan_pool img {
  z-index: 1;
}

.plan_pool_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  position: relative;
  margin: 10px auto 40px;
}

.plan_pool_head::after {
  content: "";
  background-color: #2a2a2a;
  width: 1px;
  height: 57%;
  position: absolute;
  bottom: -40px;
}

.plan_pool_head_child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 29px 5px 0;
}

.plan_pool_first_row .plan_pool_head_child {
  width: 100px;
  height: 100px;
  font-size: 36px;
}

.plan_pool_first_row {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.plan_pool_first_row::after {
  content: "";
  background-color: #161616;
  width: 50%;
  height: 1px;
  position: absolute;
}

.plan_pool_head_child::before {
  content: "";
  background: #2a2a2a;
  width: 1px;
  height: 75%;
  position: absolute;
  top: -29px;
}

.plan_pool_second_row .plan_pool_head_child {
  width: 85px;
  height: 85px;
  font-size: 30px;
  border-radius: 12px;
}

.plan_pool_second_row>div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}

.plan_pool_second_row,
.plan_pool_third_row,
.plan_pool_fourth_row,
.plan_pool_fifth_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_first_row .plan_pool_head_child::after,
.plan_pool_third_row .plan_pool_head_child::after,
.plan_pool_fourth_row .plan_pool_head_child::after {
  content: "";
  background-color: #2a2a2a;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
}

.plan_pool_third_row>div,
.plan_pool_fourth_row>div,
.plan_pool_fifth_row>div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
}

.plan_pool_second_row>div::before,
.plan_pool_third_row>div::before,
.plan_pool_third_row>div::before,
.plan_pool_fourth_row>div::before,
.plan_pool_fifth_row>div::before {
  content: "";
  background-color: #161616;
  width: 50%;
  height: 1px;
  position: absolute;
}

.plan_pool_third_row .plan_pool_head_child {
  width: 65px;
  height: 65px;
  font-size: 24px;
  border-radius: 10px;
}

.plan_pool_fourth_row .plan_pool_head_child {
  width: 45px;
  height: 45px;
  font-size: 20px;
  border-radius: 8px;
}

.plan_pool_fifth_row .plan_pool_head_child {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  font-size: 12px;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #0b0b0b;
}

.primary_modal .btn-close:focus {
  box-shadow: none;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #d7af1c;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #d7af1c;
  background-color: transparent;
  color: #111111;
  border-radius: 5px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #ffffff;
  ;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  filter: grayscale(1) hue-rotate(180deg);
}

.primary_modal a {
  text-decoration: none;
}

.plan_pool_third_row .plan_pool_head_child::after {
  display: none;
}

.custom_tooltip {
  background: #f8cd24 !important;
  color: #000 !important;
}

.custom_tooltip p {
  font-size: 18px;
}

.custom_tooltip::after {
  border-top-color: #f8cd24 !important;
}

.plan_wrapper p {
  font-size: 18px;
}


.join_now .form_sec {
  max-width: 35%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 200px;
}

.join_now .form_sec .primary_inp {
  border-radius: 5px;
}

.join_now .form_sec::placeholder,
.join_now .form_sec p {
  font-size: 16px;
  color: #909090;
  margin-bottom: 0;
  margin-left: 15px;
}

.join_now .form_sec label {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 15px;
}

.join_now .form_sec .form-check label {
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
}

.join_now .form_sec .form-check {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.join_now .form_sec .form-check-input {
  border: 2px solid #2a2a2a;
  background: transparent;
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: -2px;
}

.join_now .form_sec .form-check-input:checked {
  background-color: #f6ca23;
  border-color: #e8af16;
}

.join_now .form_sec .form-check-input:focus {
  box-shadow: none;
}

/* profileform */
.profileform label {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 15px;
}

.profileform .primary_inp {
  border-radius: 5px;
}

.navbar-brand img.img-fluid {
  width: 290px;
}

.dashbox_middle_bottom input.form-control {
  padding-right: 30px;
  text-overflow: ellipsis;
  pointer-events: none;
  cursor: not-allowed;
}
.rdt_TableCell {
  white-space: nowrap;
}
.default_owner_add {
  word-wrap: break-word;
  white-space: normal;
}
.tree_box {
  background: #da9409;
  border-radius: 10px;
  padding: 14px;
  color: #0f0f0f;
  width: 18%;
}
.tree_box h2 {
  font-size: 18px;
  font-weight: 600;
}
.tree_box h4 {
  font-size: 16px;
}
.tree_box_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -200px;
  margin-top: 120px;
}
.mobileForAlign ul {
  justify-content: center;
  margin-top: 30px;
}



/* Media Queries */
@media screen and (max-width: 1199px) {
  .plan_pool_fifth_row {
    transform: scale(0.85);
  }

  .dashbox_middle_top h2 {
    font-size: 32px;
  }

  .partner_box_right h3,
  .partner_box_left h2,
  .partner_box_left h5 {
    font-size: 20px;
  }

  .partner_box_right::before {
    left: -12px;
  }


  .main_title,
  .faq_section .h2,
  .plans h2 {
    font-size: 44px;
  }

  .counter_section h3 {
    font-size: 32px;
  }

  #faq_accodian .card-header button {
    font-size: 20px;
  }

  .plans .flex h4 {
    font-size: 34px;
  }

  .community_section .whole_box .box:hover {
    padding: 15px;
  }

  .community_section .whole_box {
    background-color: #0b0b0b;
  }

  .main_title_small {
    white-space: normal;
    text-align: center;
  }

  .main_title {
    position: relative;
    white-space: normal;
    text-align: center;
    margin-bottom: 25px;
  }

  .community_section .whole_box .above_box {
    margin-top: 0;
    z-index: 0;
    padding: 0;
    padding-bottom: 20pxs;
  }

  .community_section .whole_box .para {
    max-width: 100%;
    text-align: center;
  }

  .page_header {
    background-position: inherit;
    height: 820px;
  }

  .page_header h1 {
    max-width: 65%;
  }
}

@media screen and (max-width: 991px) {
  .plan_pool_fourth_row {
    transform: scale(0.80);
  }

  .plan_pool_fifth_row {
    transform: scale(0.65);
  }

  .plan_pool_fifth_row>div {
    margin-top: 6px;
  }

  .plan_pool_head {
    width: 110px;
    height: 110px;
    font-size: 32px;
  }

  .plan_wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .title_flex {
    display: block;
    text-align: center;
  }

  .wallet_div {
    display: block;
  }

  .inner_title {
    margin-bottom: 15px;
    font-size: 32px;
  }

  .wallet_div input.primary_inp {
    width: 100%;
  }

  .wallet_div label {
    margin: 0 0 10px 0;
  }

  .footer_panel {
    display: block;
    text-align: center;
  }

  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }

  .page_header h1 {
    font-size: 42px;
    margin-top: 180px;
  }

  .page_header h5 {
    max-width: 80%;
  }

  .counter_panel h3 {
    font-size: 40px;
  }

  .counter_panel div::before {
    background-size: 70% !important;
    top: -20px;
    background-position: center !important;
  }


  .main_title,
  .faq_section .h2,
  .plans h2 {
    font-size: 38px;
  }

  .page_header h1 {
    max-width: 100%;
  }

  .counter_section h3 {
    font-size: 28px;
  }

  #faq_accodian {
    max-width: 100%;
  }

  .community_section {
    padding: 20px 0 80px;
  }

  .community_section .whole_box .box p {
    display: block;
    text-align: center;
  }

  .community_section .whole_box .box:hover,
  .community_section .whole_box .box,
  .community_section .whole_box .col-xl-3:nth-child(1) .box {
    position: relative;
    background: none;
    align-items: center;
  }

  .counter_sectionbg,
  .community_section .whole_box {
    border-radius: 40px;
  }

  .join_now .form_sec {
    max-width: 75%;
  }
  .tree_box_panel {
    margin-bottom: 30px;
    margin-top: 40px;
  }
  .tree_box_panel .tree_box+.tree_box {
    margin-left: 15px;
  }
  .tree_box {   
    width: 50%;
}
}

@media screen and (max-width: 767px) {
  .join_now .form_sec {
    max-width: 100%;
  }
  .tree_box h4 {
    font-size: 16px;
  }
  .plans .tree {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .plans .tree .planimg {
    display: none;
  }

  .plans .tree .tree_structure {
    display: block;
  }

  .search_div {
    flex-wrap: wrap;
    justify-content: center;
  }

  .search_div button {
    top: 49%;
  }

  .dashbox_middle_top h2 {
    font-size: 28px;
  }

  .dash_box_left h4 {
    font-size: 22px !important;
  }

  .box_left h4 {
    font-size: 20px;
  }

  .box_left h3 {
    font-size: 23px;
  }

  .plan_pool {
    max-width: 100%;
  }

  .title_box .box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .title_box .box button {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .title_box .box p {
    margin-bottom: 10px;
  }

  .plan_pool_third_row .plan_pool_head_child {
    width: 40px;
    height: 40px;
    font-size: 18px;
    border-radius: 5px;
  }

  .plan_pool_fourth_row .plan_pool_head_child {
    width: 30px;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;
  }

  .plan_pool_fifth_row .plan_pool_head_child {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    font-size: 10px;
  }

  .plan_pool_second_row .plan_pool_head_child {
    width: 56px;
    height: 56px;
    font-size: 24px;
    border-radius: 8px;
  }

  .plan_pool_first_row .plan_pool_head_child {
    width: 65px;
    height: 65px;
    font-size: 28px;
    border-radius: 8px;
  }

  .dashbox_middle_top {
    display: block;
  }

  .dashbox_middle_top>div {
    margin-bottom: 30px;
  }

  .dashbox_middle_top>div:last-child {
    margin-bottom: 0;
  }

  .plan_wrapper {
    overflow-x: unset;
  }

  .search_div {
    width: 100%;
  }

  .footer_panel_left a {
    font-size: 20px;
  }

  .navbar_right {
    display: block;
    margin-top: 15px;
  }

  button.logout_btn {
    margin-bottom: 15px;
  }

  .main_navbar {
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  }

  .page_header h1 {
    font-size: 32px;
  }

  .page_header h5 {
    max-width: 100%;
    font-size: 24px;
  }

  .main_title {
    font-size: 36px;
  }

  .counter_panel {
    display: block;
    text-align: center;
  }

  .counter_panel div {
    margin-bottom: 30px;
  }

  .counter_panel div::before {
    background-size: 60% !important;
    top: -30px;
  }

  .partner_box_right::before {
    left: -56px;
  }

  .faq_section .text-right {
    text-align: left !important;
  }

  .partners_section p.inner_para a {
    white-space: normal;
    word-break: break-all;
  }

  .main_title,
  .faq_section .h2,
  .plans h2 {
    font-size: 32px;
  }

  .plans .flex {
    flex-wrap: wrap;
  }

  .community_section .whole_box .para,
  .page_header p,
  .counter_section p {
    font-size: 16px;
  }

  .counter_section .col-md-6,
  .counter_section .col-md-6:nth-child(3),
  .counter_section .col-md-6:nth-child(even) {
    border: 0;
  }

  #faq_accodian .card-header button {
    font-size: 18px;
  }

  #faq_accodian .card-body p {
    font-size: 15px;
  }

  .page_header {
    height: 950px;
  }

  #faq_accodian .card.collapsed button::after,
  #faq_accodian .card:not(.collapsed) button::after {
    width: 80px;
    height: 80px;
    background-size: contain;
  }

  .main_navbar .dropdown-menu.show {
    margin-top: -10px;
  }
}

@media screen and (max-width: 575px) {
  .plan_pool_fourth_row .plan_pool_head_child {
    width: 32px;
    height: 32px;
  }

  .plan_pool_fifth_row .plan_pool_head_child {
    width: 16px;
    height: 16px;
  }

  .dashbox_middle_bottom h2 {
    font-size: 20px;
  }

  .plan_pool_fourth_row {
    transform: scale(0.65);
  }

  .plan_pool_fifth_row {
    transform: scale(0.52);
  }

  .plan_pool_fifth_row>div {
    margin-top: -9px;
  }

  .plan_pool_fourth_row>div {
    margin-top: 6px;
  }

  .plan_pool_head {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }

  .plan_pool_third_row {
    transform: scale(0.8);
  }

  .plan_pool_third_row>div {
    margin-top: 8px;
  }

  .inner_para {
    font-size: 18px;
  }

  .main_title_small {
    font-size: 20px;
  }

  .main_title {
    font-size: 28px;
  }


  .main_title,
  .faq_section .h2,
  .plans h2 {
    font-size: 28px;
  }

  .plans .flex h4 {
    font-size: 28px;
  }

  .page_header h1 {
    margin-top: 0;
  }

  .page_header {
    background: url("../src/assets/images/mobile-bannerbg.png") no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 140px;
    padding-bottom: 25px;
    height: unset;

  }

  /* .page_header::before  {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
  } */
  /* .page_header #main{
    display: none;
  } */
  /* .page_header .coin_img{
    display: block;
    margin: auto;
    width: 200px;
  } */
}

@media screen and (max-width: 540px) {
  #faq_accodian .card-header button {
    width: 82%;
  }

  #faq_accodian .card.collapsed button::after,
  #faq_accodian .card:not(.collapsed) button::after {
    right: -78px;
    width: 68px;
    height: 68px;
    background-size: contain;
  }

  .community_section .whole_box {
    padding: 25px 15px;
    border-radius: 40px;
  }
}

@media screen and (max-width: 479px) {
  .plan_pool_fourth_row {
    transform: scale(0.515);
  }

  .plan_pool_fifth_row {
    transform: scale(0.42);
  }

  .plan_pool_fifth_row>div {
    margin-top: -18px;
  }

}

@media screen and (max-width: 500px) {
  .page_header {
    background-position: center top;
  }
}

@media screen and (max-width: 460px) {

  .page_header p {
    max-width: 100%;
    text-align: center;
  }

  .title_box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .title_box .box p {
    text-align: center;
  }

  .navbar-brand img.img-fluid {
    width: 216px;
  }
}


/* 
.coin {
  background: url("../src/assets/images/due_coin.png") no-repeat 0 0;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  position: relative;
  -webkit-transform-style: preserve-3d;
  animation: spin 3s linear infinite;
  display: flex;
}

.coin:before {
  background-color: #e1ad2f;
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 230px;
  width: 230px;

  -webkit-transform: translateZ(-0.15em);
}

.coin:after {
  background-color: #e6b936;
  content: "";
  left: 2.25em;
  position: absolute;
  height: 229px;
  width: 1.05em;
  z-index: -10;
  -webkit-transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  top: 0px;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.tails {
  background-color: #e1ad2f;
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 230px;
  width: 230px;
  -webkit-transform: translateZ(-0.005em);
  border: 1px solid #e1ad2f;
}

.heads {
  background: url("../src/assets/images/ban_pic_mirror.png") no-repeat 0 0;
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 230px;
  width: 230px;
  -webkit-transform: translateZ(-1.055em);
  border: 1px solid #e1ad2f;
}


@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

#main {
  display: block;
  width: 230px;
  height: 230px;
  top: 10px;
  left: 10px;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  animation: turn 2.5s infinite linear;
  margin: auto;
  top: 20%;
} */

.coin_wrapper {
  position: relative;
  z-index: 10;
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#f9bc25, #f9bc25);
  margin: auto;
}

.coin {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-animation: rotate 3s infinite linear;
  animation: rotate 3s infinite linear;
  transform-style: preserve-3d;
  z-index: -1;
}

.coin .side,
.coin:before,
.coin:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  background: #f9bc25;
  border-radius: 50%;
  right: -0.396em;
  text-align: center;
  line-height: 1;
  color: #f9bc25;
  text-shadow: 1px 2px 0 #f9bc25, -1px -2px 0 #f9bc25;
  transform: rotateY(-90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.coin .tails,
.coin:after {
  left: -0.396em;
  transform: rotateY(90deg);
}

.coin:before,
.coin:after {
  background: linear-gradient(#f9bc25, #f9bc25);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.coin:after {
  transform: rotateY(-90deg);
}

.imageCoin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-height: 1em;
  background-color: #f9bc25;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotateY(360deg);
  }
}


.dropdown-menu.show {
  display: block;
  background: #1e1e1e;
  color: #fff;
  font-size: 14px;
  padding-left: 16px;
  padding-top: 20px;
  padding: 14px;
}

.dropdown .dropdown-menu p {
  font-size: 16px;
  font-weight: 400;
  color: #d6dadf;
}

.tabledeatils {
  margin-top: 30px;
}

.tabledeatils h6 {
  font-size: 20px;
  font-weight: 600;
  color: #f8dc52;
  margin-bottom: 10px;
}

.tabledeatils .rdt_TableHeadRow {
  background: #151515 !important;
  color: #d1d1d1;
  font-weight: 600;
  font-size: 18px;
}

.tabledeatils .rdt_TableRow {
  background-color: #0d0d0d;
  color: #fff;
}

.tabledeatils .rdt_Pagination {
  background: #0f0f0f;
  color: #fff;
}

.tabledeatils .rdt_Pagination button {
  fill: rgb(255 255 255 / 54%);
}

.tabledeatils .rdt_Pagination button:disabled {
  opacity: 0.5;
}

.tabledeatils .rdt_Pagination select {
  background: #0f0f0f;
}

.tabledeatils .rdt_Pagination option {
  background: #0f0f0f;
  color: #fff;
}


.tabledeatils .rdt_Pagination option[selected] {
  background: #0f0f0f !important;
}